//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//AUTH0
export const UPDATE_AUTH_USER = 'update_auth_user';
export const SET_AUTH_TOKEN = 'set_auth_token';
export const SET_MOBILENO = 'set_mobileno';
export const SET_PASWD = 'set_paswd';
export const SIGNOUT_AUTH_SUCCESS = 'signout_auth_success';

//ANALYTICS-DASHBOARD
export const GET_ANALYTICS_DATA = 'get_analytics_data';

//ECOMMERCE-DASHBOARD
export const GET_ECOMMERCE_DATA = 'get_ecommerce_data';

//ACADEMY-DASHBOARD
export const GET_ACADEMY_DATA = 'get_academy_data';

//CRM-DASHBOARD
export const GET_CRM_DATA = 'get_crm_data';

//CRYPTO-DASHBOARD
export const GET_CRYPTO_DATA = 'get_crypto_data';

//CRYPTO-DASHBOARD
export const GET_HC_DATA = 'get_hc_data';

//USER MANAGEMENT
export const GET_USERMANAGE_DATA = 'get_usermanage_data';
export const GET_GUDMEDSUCCESS_DATA = 'get_gudmedsuccess_data';
export const GET_GUDMED_USER = 'get_gudmed_user';
export const RESET_USER_PASWD = 'reset_user_paswd';

//METRICS-DASHBOARD
export const GET_METRICS_DATA = 'get_metrics_data';

//WIDGETS_DASHBOARD
export const GET_WIDGETS_DATA = 'get_widgets_data';

//MAIL-APP
export const GET_MAIL_LIST = 'get_mail_list';
export const GET_FOLDER_LIST = 'get_folder_list';
export const GET_LABEL_LIST = 'get_label_list';
export const TOGGLE_MAIL_DRAWER = 'toggle_mail_drawer';
export const COMPOSE_MAIL = 'compose_mail';
export const GET_MAIL_DETAIL = 'get_mail_detail';
export const UPDATE_MAIL_FOLDER = 'update_mail_folders';
export const UPDATE_MAIL_LABEL = 'update_mail_label';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const UPDATED_MAIL_DETAIL = 'updated_mail_detail';
export const CHANGE_READ_STATUS = 'change_read_status';
export const GET_CONNECTION_LIST = 'get_connection_list';
export const NULLIFY_MAIL = 'nullify_mail';

//TODO-APP
export const GET_TASK_LIST = 'get_task_list';
export const CREATE_NEW_TASK = 'create_new_task';
export const TOGGLE_TODO_DRAWER = 'toggle_todo_drawer';
export const GET_TODO_FOLDER_LIST = 'GET_TODO_FOLDER_LIST';
export const GET_TODO_LABEL_LIST = 'GET_TODO_LABEL_LIST';
export const GET_TODO_STATUS_LIST = 'GET_TODO_STATUS_LIST';
export const GET_TODO_PRIORITY_LIST = 'GET_TODO_PRIORITY_LIST';
export const UPDATE_TASK_FOLDER = 'UPDATE_TASK_FOLDER';
export const UPDATE_TASK_LABEL = 'UPDATE_TASK_LABEL';
export const UPDATE_TASK_STARRED_STATUS = 'UPDATE_TASK_STARRED_STATUS';
export const GET_TASK_DETAIL = 'GET_TASK_DETAIL';
export const UPDATE_TASK_DETAIL = 'UPDATE_TASK_DETAIL';
export const GET_TODO_STAFF_LIST = 'GET_TODO_STAFF_LIST';

//CONTACT_APP
export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const GET_CONTACT_FOLDER_LIST = 'GET_CONTACT_FOLDER_LIST';
export const GET_CONTACT_LABEL_LIST = 'GET_CONTACT_LABEL_LIST';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT_LABEL = 'UPDATE_CONTACT_LABEL';
export const UPDATE_CONTACT_STARRED_STATUS = 'UPDATE_CONTACT_STARRED_STATUS';
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
export const TOGGLE_CONTACT_DRAWER = 'TOGGLE_CONTACT_DRAWER';
export const UPDATE_CONTACT_DETAIL = 'UPDATE_CONTACT_DETAIL';
export const CREATE_NEW_CONTACT = 'CREATE_NEW_CONTACT';

//SCRUMBOARD_APP
export const GET_MEMBER_LIST = 'GET_MEMBER_LIST';
export const GET_SCRUM_LABEL_LIST = 'GET_SCRUM_LABEL_LIST';
export const GET_BOARDS = 'GET_BOARDS';
export const GET_BOARD_DETAIL = 'GET_BOARD_DETAIL';
export const ADD_BOARD_LIST = 'ADD_BOARD_LIST';
export const ADD_LIST_CARD = 'ADD_LIST_CARD';
export const EDIT_LIST_CARD = 'EDIT_LIST_CARD';
export const DELETE_LIST_CARD = 'DELETE_LIST_CARD';
export const DELETE_BOARD = 'DELETE_BOARD';
export const ADD_NEW_BOARD = 'ADD_NEW_BOARD';
export const DELETE_LIST = 'DELETE_LIST';
export const EDIT_BOARD_DETAIL = 'EDIT_BOARD_DETAIL';
export const EDIT_BOARD_LIST = 'EDIT_BOARD_LIST';

//CHAT_APP
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_USER_MESSAGES = 'get_user_messages';
export const ADD_NEW_MESSAGE = 'add_new_message';
export const EDIT_MESSAGE = 'edit_message';
export const DELETE_MESSAGE = 'delete_message';
export const DELETE_USER_MESSAGES = 'delete_user_messages';
export const TOGGLE_CHAT_DRAWER = 'toggle_chat_drawer';
export const SELECT_USER = 'select_user';

//WALL_APP
export const GET_WALL_DATA = 'get_wall_data';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_NEW_POST = 'create_new_post';
export const UPDATE_POST = 'update_post';

//USER_LIST
export const GET_USER_LIST = 'GET_USER_LIST';

//ECOMMERCE_LIST
export const GET_ECOMMERCE_LIST = 'get_ecommerce_list';
export const SET_PRODUCT_VIEW_TYPE = 'set_product_view_type';
export const SET_FILTER_DATA = 'set_filter_data';
export const SET_PRODUCT_DATA = 'set_product_data';
export const GET_RECENT_ORDER = 'get_recent_order';
export const GET_CUSTOMERS = 'get_customers';
export const ADD_CART_ITEM = 'add_cart_item';
export const REMOVE_CART_ITEM = 'remove_cart_item';
export const UPDATE_CART_ITEM = 'update_cart_item';
export const SET_CART_ITEMS = 'set_cart_items';

//CK-EDITOR
export const GET_BALLOON_BLOCK_DATA = 'get_balloon_block_data';
export const UPDATE_BALLOON_BLOCK_DATA = 'update_balloon_block_data';
export const GET_BALLOON_DATA = 'get_balloon_data';
export const UPDATE_BALLOON_DATA = 'update_balloon_data';
export const GET_CLASSIC_DATA = 'get_classic_data';
export const UPDATE_CLASSIC_DATA = 'update_classic_data';
export const GET_INLINE_DATA = 'get_inline_data';
export const UPDATE_INLINE_DATA = 'update_inline_data';
export const GET_DOCUMENT_DATA = 'get_document_data';
export const UPDATE_DOCUMENT_DATA = 'update_document_data';
export const GET_CUSTOM_DATA = 'get_custom_data';
export const UPDATE_CUSTOM_DATA = 'update_custom_data';

//GALLERY
export const GET_GALLERY_PHOTO = 'get_gallery_photo';

//Delete USER
export const DELETE_USER_DATA = 'delete_user_data';

//Dashboard Data
export const DASHBOARD_PENDING_DATA = 'dashboard_pending_data';
export const DASHBOARD_PENDING_COUNT = 'dashboard_pending_count';
export const DASHBOARD_PENDING_PATIENT_DATA = 'dashboard_pending_patient_data';
export const PATIENT_SUCCESS_MSG = 'patient_success_msg';
export const ERX_SUCCESS_MSG = 'erx_success_msg';
export const REJECT_ERX_SUCCESS_MSG = 'reject_erx_success_msg';
export const MEDICINE_SUCCESS_MSG = 'medicine_success_msg';
export const REJECT_ERX_MSG = 'rehject_erx_msg';
export const DASHBOARD_ERX_DATA = 'dashboard_erx_data';
export const ERX_IMG_ARR = 'erx_img_arr';
export const ERX_IMG_OCR_ARR = 'erx_img_ocr_arr';
export const ERXLIST_IMG_ARR = 'erxlist_img_arr';
export const ERXLIST_IMG_OCR_ARR = 'erxlist_img_ocr_arr';

export const DASHBOARD_RX_DETAILS = 'dashboard_rx_details';
export const DASHBOARD_ERX_DOC_OBSV = 'dashboard_erx_doc_obsv';
export const DASHBOARD_ERX_LAB_OBSV = 'dashboard_erx_lab_obsv';
export const DASHBOARD_ERX_PATIENT_VITAL = 'dashboard_erx_patient_vital';
export const DASHBOARD_ERX_DOCPROCEDURE = 'dashboard_erx_docprocedure';
export const DASHBOARD_ERX_MEDICINE_DETAIL = 'dashboard_erx_medicine_detail';
export const DASHBOARD_GRX_MEDICINE_DETAIL = 'DASHBOARD_GRX_MEDICINE_DETAIL';

export const DASHBOARD_INSERT_PRESCRIPTION = 'dashboard_insert_prescription';
export const DASHBOARD_INPROGRESSS_DATA = 'dashboard_inprogress_data';
export const DASHBOARD_REJECT_DATA = 'dashboard_reject_data';
export const DASHBOARD_REJECT_COUNT = 'dashboard_reject_count';
export const DASHBOARD_COMPLETED_DATA = 'dashboard_completed_data';
export const DASHBOARD_COMPLETED_COUNT = 'dashboard_completed_count';
export const DASHBOARD_INPROGRESSS_COUNT = 'dashboard_inprogresss_count';
export const DELETE_MEDCINE_SUCCESS_MSG = 'delete_medicine_success_msg';
export const UNIT_DATA = 'unit_data';
//doctor data
export const GET_DOCTOR_EDITDATA = 'get_doctor_editdata';
export const DOCTOR_SUCCESS_MSG = 'doctor_success_msg';
export const DOCTOR_UPDATE_MSG = 'doctor_update_msg';
export const SET_DOCTOR_ID_NAME = 'set_doctor_id_name';
export const BUCKET_CAPTCHA = 'BUCKET_CAPTCHA';
export const DOCTOR_SPECIALIZATION = 'doctor_specialization';
export const DOCTOR_SUBSPECIALIZATION = 'doctor_subspecialization';
export const DOCTOR_QUALIFICATION = 'doctor_qualification';
export const UPDATE_SETTINGS = 'update_settings';
export const APPOINTMENT_COMPLETED_DATA = 'APPOINTMENT_COMPLETED_DATA';
export const APPOINTMENT_UPCOMING_DATA = 'APPOINTMENT_UPCOMING_DATA';
export const DEPARTMENT_MASTER = 'department_master';
export const STATE_MASTER = 'state_master';
export const OPT_NOTES = 'opt_notes';
export const EXIST_COUNT = 'exist_count';
export const DOCTOR_SEARCH_ARRAY = 'doctor_search_array';
export const DOCTOR_SEARCH_DATA = 'doctor_search_data';

export const PATIENT_COMPLETED_DATA = 'patient_completed_data';
export const PATIENT_OLDCOMPLETED_DATA = 'patient_oldcompleted_data';
export const EXCEL_COMPLETE_DATA = 'EXCEL_COMPLETE_DATA';
export const PATIENT_COMPLETED_COUNT = 'patient_completed_count';
export const PATIENT_COMPLETED_OLDCOUNT = 'patient_completed_oldcount';
export const PATIENT_FILTER_COUNT = 'patient_filter_count';
export const PATIENTOLD_FILTER_COUNT = 'patientold_filter_count';
export const PATIENTLIST_COMPLETED_DATA = 'patientlist_completed_data';
export const PATIENTOLDLIST_COMPLETED_DATA = 'patientoldlist_completed_data';
export const PATIENTLIST_COMPLETED_COUNT = 'patientlist_completed_count';
export const PATIENTOLDLIST_COMPLETED_COUNT = 'patientoldlist_completed_count';
export const PATIENT_SEARCH = 'PATIENT_SEARCH';
export const DISEASE_SEARCH = 'DISEASE_SEARCH';
export const FORGOT_PASWD_SUCCESS = 'forgot_paswd_success';
export const OTP_TIME = 'otp_time';
export const FORGOT_PASWD_ERROR = 'forgot_paswd_error';

export const PATIENT_SIGNUP = 'patient_signup';
export const OTP_SUCCESS = 'otp_success';
export const MOBILE_OTP_SUCCESS = 'mobile_otp_success';
export const OTP_ERROR = 'otp_error';
export const OTP_SIGNUP_ERROR = 'otp_signup_error';
export const SIGNIN_ERROR = 'signin_error';
export const OTP_UPDTE_ERROR = 'otp_update_error';
export const OTP_UPDTE_SUCCESS = 'OTP_UPDTE_SUCCESS';
export const DOCTOR_APPROVE_STATUS = 'doctor_approve_status';

//my profile
// export const STATE_MASTER='state_master';
export const DOCTOR_PROFILE = 'doctor_profile';
export const SAVE_PROFILES = 'save_profiles';
export const HOSPITAL_SEARCH_DATA = 'hospital_search_data';
export const SAVE_PREFERENCES = 'SAVE_PREFERENCES';
export const ERX_OPERATOR_NOTES = 'erx_operator_notes';
export const resulttitle = 'resulttitle';
export const bloodsugarList = 'bloodsugarList';
export const CHANGE_MOBILENO_RES = 'change_mobileno_res';
export const PATIENT_COMPLETED_PATIENT_NAME = 'patient_completed_patient_name';
export const ERX_DATA_HTML = 'ERX_DATA_HTML';
export const WORD_PREVIEW_CLIENT = 'WORD_PREVIEW_CLIENT';
export const ERX_DATA_HTML_NO_DATA = 'ERX_DATA_HTML_NO_DATA';

//Report data
export const INVESTIGATION_CATEGORY_DATA = 'INVESTIGATION_CATEGORY_DATA';
export const INVESTIGATION_DISEASE_DATA = 'INVESTIGATION_DISEASE_DATA';
export const REPORT_DETAILS = 'REPORT_DETAILS';
export const SEARCH_REPORTS_DATA = 'SEARCH_REPORTS_DATA';
export const TAG_CATEGORY_DATA = 'TAG_CATEGORY_DATA';
export const TAG_DISEASE_DATA = 'TAG_DISEASE_DATA';
export const PATIENT_LISTS = 'PATIENT_LISTS';

//Get search patient data
export const SEARCH_PATIENT_DATA = 'search_patient_data';
export const SEARCH_PATIENT_OLDDATA = 'search_patient_olddata';
export const SEARCH_DISEASE_DATA = 'search_disease_data';
export const TODAY_APPOINTMENT_DATA = 'TODAY_APPOINTMENT_DATA';
export const SEARCH_SYMPTOMS_DATA = 'search_symptoms_data';
export const PASSWORD_REQUEST_TAG = 'PASSWORD_REQUEST_TAG';
export const SETPASS_DOCID = 'SETPASS_DOCID';
export const SETPASS_MOB = 'SETPASS_MOB';
